




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Search extends Vue {
  @Prop() onSearchSubmit!: Function;
  searchText = "";

  onSearch() {
    this.onSearchSubmit(this.searchText);
  }
}
