



















































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  CheckSquareIcon,
  TrashIcon,
  EditIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon
} from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import Modal from "../components/ui/Modal.vue";
import NoData from "../components/ui/No-Data.vue";
import Search from "../components/ui/Search.vue";
import DaterangePicker from "../components/ui/Daterange-Picker.vue";
import moment from "moment";

import XLSX from "xlsx";

@Component({
  components: {
    CheckSquareIcon,
    TrashIcon,
    EditIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Loader,
    Modal,
    NoData,
    DownloadIcon,
    Search,
    DaterangePicker,
  },
})
export default class Lease extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  assetsList: any = [];
  isLoading = true;
  showDeleteModal = false;
  showNoData = false;
  noDataType = "";
  filterType = "nofilter";
  lastQueryStartDate = "";
  lastQueryEndDate = "";
  lastQuerySearchText = "";


  mounted() {
    setTimeout(() => {
      this.fetchLeaseList();
    }, 0);
  }

  onReloadData() {
    this.filterType = "nofilter";
    setTimeout(() => {
      this.fetchLeaseList();
    }, 0);
  }


  onDateRangeChange(dateRange) {
    this.lastQueryStartDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    this.lastQueryEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    this.fetchLeaseList();
  }
  onSearchSubmit(seachText) {
    this.lastQuerySearchText = seachText;
    this.fetchLeaseList();
  }

  onGenerateReport() {
    if (this.assetsList.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.assetsList.map((x: any) => ({
          customer: x.customer,
          register_no: x.register_no,
          currency: x.currency,
          rental_start_date: x.rental_start_date,
          rental_end_date: x.rental_end_date,
          status: x.status,
          contract_value: x.contract_value,
          transportation_charges: x.transportation_charges,
          normal_amount: x.normal_amount,
          overtime_amount: x.overtime_amount,
          reimbursements: x.reimbursements,
          total_claimable_amount: x.total_claimable_amount,
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "lease.xlsx");
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No Lease found",
        type: "Danger",
      });
    }
  }

  async onGenerateMISReport() {
    await fetch(`${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/pdf`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "lease-report-" +
          new Date(Date.now()).toLocaleDateString().replaceAll("/", "-") +
          ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async fetchLeaseList() {
    try {
      this.showNoData = false;
      this.isLoading = true;

      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease?`;

      if (this.filterType !== 'nofilter' && this.filterType !== 'daterange') {
        url += `${this.filterType}=${this.lastQuerySearchText}`;
      } else if (this.filterType === 'daterange') {
        url += `startDate=${this.lastQueryStartDate}&endDate=${this.lastQueryEndDate}`;
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        this.assetsList = await response.json();
        if (this.assetsList) {
          this.assetsList = [...this.assetsList.reverse()];
        }
        this.assetsLength = this.assetsList.length;
        this.initPaginations(this.assetsLength);

        if (this.assetsList.length === 0) {
          this.noDataType = "Empty";
          this.showNoData = true;
        }
      } else {
        this.noDataType = "Error";
        this.showNoData = true;
      }
    } catch (e) {
      this.noDataType = "Error";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  initPaginations(length: number) {
    this.assetsEndIndex = length > 5 ? 5 : length;
    this.assetsTotalPages =
      this.assetsList.length % 5 === 0
        ? Math.floor(this.assetsList.length / 5)
        : Math.floor(this.assetsList.length / 5 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.assetsList.length > this.assetsStartIndex + 5
        ? this.assetsStartIndex + 5
        : this.assetsList.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 5 <= 0 ? 0 : this.assetsStartIndex - 5;
  }

  onShowDeleteModal(id: string) {
    this.showDeleteModal = true;
    this.idToBeDeleted = id;
  }

  idToBeDeleted = "";
  async onDeleteLease() {
    if (this.idToBeDeleted !== "") {
      try {
        const response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/${this.idToBeDeleted}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.status === 200) {
          this.$store.commit("showToastMessage", {
            boldText: "Successfully!",
            text: "Lease deleted",
            type: "Success",
          });
          this.onReloadData();
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Failed!",
            text: "Something went wrong",
            type: "Danger",
          });
        }
      } catch (e) {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: e.message,
          type: "Danger",
        });
      } finally {
        this.onHideDeleteModal();
      }
    } else {
      this.onHideDeleteModal();
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "Invalid id selected",
        type: "Danger",
      });
    }
  }

  onHideDeleteModal() {
    this.showDeleteModal = false;
  }
}
