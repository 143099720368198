













































































import { Component, Prop, Vue } from "vue-property-decorator";
import { AlertTriangleIcon } from "vue-feather-icons";

@Component({
  components: {
    AlertTriangleIcon,
  },
})
export default class Modal extends Vue {
  @Prop() showModal = false;
  @Prop() title!: string;
  @Prop() description!: string;
  @Prop() btnText!: string;
  @Prop() type!: "Success" | "Warning" | "Danger";
  @Prop() onHideModal!: Function;
  @Prop() onSubmitModal!: Function;
}
